import * as React from "react";
import Layout from "../components/layout";

import { StaticImage } from "gatsby-plugin-image";

import Helmet from "react-helmet";

export default function Index() {
  return (
    <Layout>
            <Helmet>
        <meta charSet="utf-8" />
        <title>Saskatchewan R.M. Maps</title>
        <meta name="description" content="Saskatchewan R.M. Maps" />
        <meta name="image" content="https://awmcphee.ca/val_marie_thumb.jpg" />
        <meta property="og:image" content="https://awmcphee.ca/val_marie_thumb.jpg"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@ksituan"/>
        <meta name="twitter:title" content="Saskatchewan R.M. Maps"/>
        <meta name="twitter:description" content="Historic R.M. map series, freshened up with modern digital relief."/>
        <meta name="twitter:image" content="https://awmcphee.ca/val_marie_thumb.jpg"/>
      </Helmet>
      <h1>Saskatchewan R.M. Maps</h1>
      <h2>Custom requests available</h2>
      <p>Historic sectional map sheets have been combined with modern relief shading, highlighting country schoolhouses, historic farm locations, and past highway alignments.</p>
      <p><strong>These are <em>not</em> current land ownership maps.</strong> They are for historical interest and general reference only. They are all printed on 12” x 18” cardstock.</p>
      <p>All retail for $25 a copy, $10 flat shipping.</p>
      <h2>Current Inventory</h2>
      <div className="portfolio">
        <div className="pfs">
          <StaticImage
            src="../rm/Auvergne.jpg"
            alt="A map of the Rural Municipality of Auvergne No. 76."
          />
          <h4>Auvergne No. 76</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Bone Creek.jpg"
            alt="A map of the Rural Municipality of Bone Creek No. 108."
          />
          <h4>Bone Creek No. 108</h4>
        </div>     
        <div className="pfs">
          <StaticImage
            src="../rm/Carmichael.jpg"
            alt="A map of the Rural Municipality of Carmichael No. 109."
          />
          <h4>Carmichael No. 109</h4>
        </div>  
        <div className="pfs">
          <StaticImage
            src="../rm/Coulee.jpg"
            alt="A map of the Rural Municipality of Coulee No. 136."
          />
          <h4>Coulee No. 136</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Excel.jpg"
            alt="A map of the Rural Municipality of Excel No. 71."
          />
          <h4>Excel No. 71</h4>
        </div>    
        <div className="pfs">
          <StaticImage
            src="../rm/Excelsior.jpg"
            alt="A map of the Rural Municipality of Excelsior No. 166."
          />
          <h4>Excelsior No. 166</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Glen Bain.jpg"
            alt="A map of the Rural Municipality of Glen Bain No. 105."
          />
          <h4>Glen Bain No. 105</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Glen McPherson.jpg"
            alt="A map of the Rural Municipality of Glen McPherson No. 46."
          />
          <h4>Glen McPherson No. 46</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Grassy Creek.jpg"
            alt="A map of the Rural Municipality of Grassy Creek No. 78."
          />
          <h4>Grassy Creek No. 78</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Gravelbourg.jpg"
            alt="A map of the Rural Municipality of Gravelbourg No. 104."
          />
          <h4>Gravelbourg No. 104</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Gull Lake.jpg"
            alt="A map of the Rural Municipality of Gull Lake No. 139."
          />
          <h4>Gull Lake No. 139</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Lac Pelletier.jpg"
            alt="A map of the Rural Municipality of Lac Pelletier No. 107."
          />
          <h4>Lac Pelletier No. 107</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Lawtonia.jpg"
            alt="A map of the Rural Municipality of Lawtonia No. 135."
          />
          <h4>Lawtonia No. 135</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Lone Tree.jpg"
            alt="A map of the Rural Municipality of Lone Tree No. 18."
          />
          <h4>Lone Tree No. 18</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Mankota.jpg"
            alt="A map of the Rural Municipality of Mankota No. 45."
          />
          <h4>Mankota No. 45</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Maple Creek.jpg"
            alt="A map of the Rural Municipality of Maple Creek No. 111."
          />
          <h4>Maple Creek No. 111</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Pinto Creek.jpg"
            alt="A map of the Rural Municipality of Pinto Creek No. 75."
          />
          <h4>Pinto Creek No. 75</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Rodgers.jpg"
            alt="A map of the Rural Municipality of Rodgers No. 133."
          />
          <h4>Rodgers No. 133</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Saskatchewan Landing.jpg"
            alt="A map of the Rural Municipality of Saskatchewan Landing No. 167."
          />
          <h4>Saskatchewan Landing No. 167</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Shamrock.jpg"
            alt="A map of the Rural Municipality of Shamrock No. 134."
          />
          <h4>Shamrock No. 134</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Stonehenge.jpg"
            alt="A map of the Rural Municipality of Stonehenge No. 73."
          />
          <h4>Stonehenge No. 73</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Sutton.jpg"
            alt="A map of the Rural Municipality of Sutton No. 103."
          />
          <h4>Sutton No. 103</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Val Marie 16.jpg"
            alt="A map of the Rural Municipality of Val Marie No. 17."
          />
          <h4>Val Marie No. 17 (L.I.D. 16)</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Val Marie 17.jpg"
            alt="A map of the Rural Municipality of Val Marie No. 17."
          />
          <h4>Val Marie No. 17 (L.I.D. 17)</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Val Marie 47.jpg"
            alt="A map of the Rural Municipality of Val Marie No. 17."
          />
          <h4>Val Marie No. 17 (L.I.D. 47)</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Webb.jpg"
            alt="A map of the Rural Municipality of Webb No. 138."
          />
          <h4>Webb No. 138</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Whiska Creek.jpg"
            alt="A map of the Rural Municipality of Whiska Creek No. 106."
          />
          <h4>Whiska Creek No. 106</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Wise Creek.jpg"
            alt="A map of the Rural Municipality of Wise Creek No. 77."
          />
          <h4>Wise Creek No. 77</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../rm/Wood River.jpg"
            alt="A map of the Rural Municipality of Wood River No. 74."
          />
          <h4>Wood River No. 74</h4>
        </div>
      </div>
      <p>Contact me about larger RMs requiring large-format layouts, the specific sources used, or anything else. I'm always happy to work with local historical societies and other groups.</p>
    </Layout>
  );
}
